import Vue from "vue"
import VueRouter from "vue-router";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = new VueRouter({
    mode:"history",
    routes:[
        {
            path:"/",
            name:"Home",
            component:() => import('@/view/Home/Home.vue'),
        },
        {
            path:"/index/:name",
            name:"index1",
            component:() => import('@/view/Home/Home.vue'),
        },
        // 产品与服务
        {
            path:"/productService",
            name:"productService",
            component:() => import('@/view/productService/productService.vue'),
        },
        {
            path:"/product1",
            name:"Product1",
            component:() => import('@/view/Product1/Product1.vue'),
        },
        {
            path:"/product2",
            name:"Product2",
            component:() => import('@/view/Product2/Product2.vue'),
        },
        {
            path:"/product3",
            name:"Product3",
            component:() => import('@/view/Product3/Product3.vue'),
        },
        {
            path:"/product4",
            name:"Product4",
            component:() => import('@/view/Product4/Product4.vue'),
        },
        // 行业解决方案
        {
            path:"/industrySolutions",
            name:"industrySolutions",
            component:() => import('@/view/industrySolutions/industrySolutions.vue'),
        }, 
        {
            path:"/solution1",
            name:"Solution1",
            component:() => import('@/view/Solution1/Solution1.vue'),
        }, 
        {
            path:"/solution2",
            name:"Solution2",
            component:() => import('@/view/Solution2/Solution2.vue'),
        },
        {
            path:"/solution3",
            name:"Solution3",
            component:() => import('@/view/Solution3/Solution3.vue'),
        },
        {
            path:"/solution4",
            name:"Solution4",
            component:() => import('@/view/Solution4/Solution4.vue'),
        },
        {
            path:"/solution5",
            name:"Solution5",
            component:() => import('@/view/Solution5/Solution5.vue'),
        }
    ]
})

// routes.beforeEach((to,from,next) => {
//     console.log("111111")
//     var toRouter = to.name
//     if(typeof(toRouter)=='undefined' || toRouter==null || toRouter==''){
//         next({
//             name:'Home'
//         });
//     }
// })
// routes.beforeEach((to, from, next) => {
//     var prevRouter = from.name
//     if(!(prevRouter==null || prevRouter=='' || typeof(prevRouter)=='undefined')){
//         window.localStorage.setItem('prevRouter',prevRouter)
//     }
//     var name = to.name;
//     let accessToken = window.localStorage.getItem('accessToken') || Cookies.get('accessToken')
//     if ((accessToken=="" || accessToken==null || typeof accessToken == "undefined") && name !== 'Login') {
//         next({
//             name:'Login'
//         });
//     }else if(!(accessToken=="" || accessToken==null || typeof accessToken == "undefined") && from.name == 'Login'){
//         next({
//             name:'Home'
//         });
//     }
//     else if(!(accessToken=="" || accessToken==null || typeof accessToken == "undefined") && name == "Login"){
//         next({
//             name:'Home'
//         });
//     }else{
//         next()
//     }
// })

export default routes;
import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import store from './store'
import { setStore, getStore, removeStore } from '@/utils/storage'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'

Vue.config.productionTip = false

Vue.prototype.setStore = setStore;
Vue.prototype.getStore = getStore;
Vue.prototype.removeStore = removeStore;
Vue.use(ViewUI, {
  i18n: (key, value) => i18n.t(key, value)
});

import 'swiper/css/swiper.min.css';
import 'swiper/js/swiper.min.js';

new Vue({
  router,
  store,
  mounted() {
    this.$store.commit("setUserInfo", { nickname: localStorage.nickname, avatar: localStorage.avatar });
    // 显示打开的页面的列表
    // this.$store.commit('setOpenedList');
    // this.$store.commit('initCachepage');
  },
  render: h => h(App),
}).$mount('#app')

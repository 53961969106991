<template>
    <div class="top_bar">
        <div class="main_range">
            <img v-if="logoType" src="./../../assets/topBar/xy_logo1.png" alt="">
            <img v-else src="./../../assets/topBar/xy_logo2.png" alt="">
            <div class="right_nav">
                <div id="nav">
                    <span @click="clickNav(1,'Home','banner_area')">首页</span>
                    <span @click="clickNav(2,'productService')">产品与服务</span>
                    <span @click="clickNav(3,'industrySolutions')">行业解决方案</span>
                    <span @click="clickNav(4,'index1','partners_area')">合作伙伴</span>
                    <span @click="clickNav(5,'index1','contact_us_area')">关于我们</span>
                </div>
                <div class="line" ref="line"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'topBar',
    props:{
        
    },
    data(){
        return{
            nav:document.querySelector('#nav'),
            num:1,   // 点击第几个导航栏
            switch:true,  // window.onresize去抖动优化
            logoType:true,  // 不同页面不同的logo
        }
    },

    watch: {
        // 监听路由，更改头部tab栏的样式及状态
        '$route.name'(to, from){
            if(to=='Home'){
                this.tabColumn(1)
            }else if(to=='Solution1' || to=='Solution2' || to=='Solution3' || to=='Solution4' || to=='Solution5' || to=='industrySolutions'){
                this.tabColumn(3)
            }else if(to=='Product1' || to=='Product2' || to=='Product3' || to=='Product4' || to=='productService'){
                this.tabColumn(2)
            }
        },
    },

    methods:{
        // tab栏样式变化
        tabColumn(num){
            if(num == 1){
                this.logoType = true
                for(var i=0; i<this.nav.children.length; i++){
                    this.nav.children[i].style.color = '#000';
                }
                this.$refs.line.style.backgroundColor = '#409AD1';
                this.$refs.line.style.left = 20+'px'
                this.$refs.line.style.width = this.nav.children[0].offsetWidth+'px' 
                this.nav.children[0].style.color = '#409AD1';
            }else if(num == 2){
                this.logoType = false
                for(var i=0; i<this.nav.children.length; i++){
                    this.nav.children[i].style.color = '#fff';
                }
                this.$refs.line.style.backgroundColor = '#fff';
                this.$refs.line.style.left = 20*2+this.nav.children[0].offsetWidth+'px'
                this.$refs.line.style.width = this.nav.children[1].offsetWidth+'px'
            }else if(num == 3){
                this.logoType = false
                for(var i=0; i<this.nav.children.length; i++){
                    this.nav.children[i].style.color = '#fff';
                }
                this.$refs.line.style.backgroundColor = '#fff';
                this.$refs.line.style.left = 20*3+this.nav.children[0].offsetWidth+this.nav.children[1].offsetWidth+'px'
                this.$refs.line.style.width = this.nav.children[2].offsetWidth+'px'
            }else if(num == 4){
                this.logoType = true
                for(var i=0; i<this.nav.children.length; i++){
                    this.nav.children[i].style.color = '#000';
                }
                this.$refs.line.style.backgroundColor = '#409AD1';
                this.$refs.line.style.left = 20*4+this.nav.children[0].offsetWidth+this.nav.children[1].offsetWidth+this.nav.children[2].offsetWidth+'px'
                this.$refs.line.style.width = this.nav.children[3].offsetWidth+'px' 
                this.nav.children[3].style.color = '#409AD1';
            }else if(num == 5){
                this.logoType = true
                for(var i=0; i<this.nav.children.length; i++){
                    this.nav.children[i].style.color = '#000';
                }
                this.$refs.line.style.backgroundColor = '#409AD1';
                this.$refs.line.style.left = 20*5+this.nav.children[0].offsetWidth+this.nav.children[1].offsetWidth+this.nav.children[2].offsetWidth+this.nav.children[3].offsetWidth+'px'
                this.$refs.line.style.width = this.nav.children[4].offsetWidth+'px' 
                this.nav.children[4].style.color = '#409AD1';
            }
        },
        // 点击导航栏
        clickNav(num,linkName,id){
            this.tabColumn(num)
            this.$router.push({
                name:linkName,
                params:{
                    name:id
                }
            })
        }
    },

    mounted(){
        this.nav = document.querySelector('#nav');
        this.setStore('currentRoute',this.$route.path)
        // 已优化！！ 去抖动优化
        // window.onresize = () => {
        //     if (this.switch) {
        //         this.clickNav(this.num)
        //         this.switch = false
        //         setTimeout(() => {
        //             this.switch = true
        //         }, 10)
        //     }
		// };
    },
}
</script>

<style scoped lang="scss">
@import './topBar.scss'
</style>

<template>
  <div id="app">
    <top-bar></top-bar>
    <router-view></router-view>
    <buttom-bar></buttom-bar>
  </div>
</template>

<script>
import topBar from './components/topBar/topBar.vue'
import buttomBar from './components/buttomBar/buttomBar.vue'

export default {
  name: 'App',
  components:{
    topBar,
    buttomBar,
  },
  data(){
    return{
    
    }
  },


  methods:{
    beforeunloadFn(e){
        this.$router.push({name:'Home'})
    }
  },


  mounted(){
    // 禁止浏览器滚动播放
    window.addEventListener("mousewheel",function (event) {
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
        }
      },
      { passive: false }
    );

    //firefox
    window.addEventListener("DOMMouseScroll",function (event) {
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
        }
      },
      { passive: false }
    );

    // window.addEventListener('load', e => this.beforeunloadFn(e));
  }
}
</script>

<style>
@import './styles/init.css';
</style>
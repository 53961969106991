<template>
    <div class="buttom_bar">
        <img src="./../../assets/buttomBar/xy_logo.png" alt="">
        <div class="link_box">
            <p>企业介绍</p>
            <span class="line"></span>
            <p>资深团队</p>
            <span class="line"></span>
            <p>关于我们</p>
            <span class="line"></span>
            <p>联系我们</p>
        </div>
        <p>浙江云谷旭源科技有限公司</p>
        <p @click="goToLink" style="cursor: pointer;">浙ICP备2022033696号-1</p>
        <p>Copyright 1997-2015 Creawor All Rights Reserved</p>
    </div>
</template>

<script>
export default{
    name:'buttomBar',
    props:{
        
    },
    data(){
        return{
            
        }
    },


    methods:{   
        goToLink(){
            window.open('https://beian.miit.gov.cn/#/Integrated/recordQuery','_blank')
        }
    },


    mounted(){
        
    },
}
</script>

<style scoped lang="scss">
@import './buttomBar.scss'
</style>
